
import { JobQuoteListing } from "@/views/job_quotes/Index.vue";
import { defineComponent, onMounted, ref } from "vue";
import { Modal } from "bootstrap";
import { useStore } from "vuex";
import { fireToast } from "@/core/helpers/swal";

export default defineComponent({
  name: "delete-quote-modal",
  emits: ["deleted"],
  setup: function(props, { emit }) {
    const element = ref<HTMLElement | null>(null);
    const modal = ref<Modal | null>(null);
    const quote = ref<JobQuoteListing | null>(null);
    const store = useStore();
    const loading = ref<boolean>(false);

    function setLoading(state: boolean): void {
      loading.value = state;
    }

    onMounted((): void => {
      element.value = document.getElementById("delete-quote-modal");
      if (element.value) {
        modal.value = new Modal(element.value);
      }
    });

    function toggleModal(toggle: boolean, jobQuote: null | JobQuoteListing) {
      if (toggle && quote) {
        quote.value = jobQuote;
        modal.value?.show();
      } else {
        modal.value?.hide();
      }
    }

    function handleDelete(): void {
      setLoading(true);
      store
        .dispatch("JobQuotesModule/deleteJobQuote", quote.value?.id)
        .then(() => {
          toggleModal(false, null);
          fireToast("Job quote deleted successfully", true);
          setLoading(false);
          emit("deleted");
        })
        .catch(() => {
          fireToast("An error occurred while trying to delete quote", false);
          setLoading(false);
        });
    }

    return {
      handleDelete,
      toggleModal,
      loading,
      quote
    };
  }
});
