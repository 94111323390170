
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import Pagination from "@/components/pagination/Pagination.vue";
import DeleteQuoteModal from "@/views/job_quotes/DeleteQuoteModal.vue";

export interface JobQuoteListing {
  id: number;
  title: string;
  address: string;
  company: string;
}

export default defineComponent({
  name: "job-quotes-index",
  components: { DeleteQuoteModal, Pagination },
  setup: function() {
    const store = useStore();
    const quotes = computed<JobQuoteListing[]>(
      (): JobQuoteListing[] => store.getters["JobQuotesModule/quotes"]
    );
    const loading = ref<boolean>(false);
    const generating = ref<number[]>([]);
    const deleteQuoteModal = ref<InstanceType<typeof DeleteQuoteModal>>();
    const pagination = reactive({
      current: 1,
      total: 1
    });

    function setLoading(value: boolean): void {
      loading.value = value;
    }

    function fetchQuotes() {
      setLoading(true);
      store
        .dispatch("JobQuotesModule/fetchQuotes", pagination)
        .then(data => {
          pagination.current = data.current_page;
          pagination.total = data.last_page;
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }

    function handleChangePage(page: number): void {
      pagination.current = page;
      fetchQuotes();
    }

    onMounted((): void => {
      fetchQuotes();
    });

    function handleDelete(jobQuote: JobQuoteListing): void {
      deleteQuoteModal.value?.toggleModal(true, jobQuote);
    }

    function unshiftLoading(quoteId: number): void {
      const index = generating.value.findIndex(i => i == quoteId);
      if (-1 !== index) {
        generating.value.splice(index, 1);
      }
    }

    function handleGenerate(quoteId: number): void {
      generating.value.push(quoteId);
      store
        .dispatch("JobQuotesModule/downloadJobQuote", quoteId)
        .then(() => {
          unshiftLoading(quoteId);
        })
        .catch(() => {
          unshiftLoading(quoteId);
        });
    }

    function isGenerating(quoteId: number): boolean {
      return generating.value.findIndex(i => i == quoteId) !== -1;
    }

    function updateRows(): void {
      pagination.current = 1;
      fetchQuotes();
    }

    return {
      deleteQuoteModal,
      handleChangePage,
      handleGenerate,
      handleDelete,
      isGenerating,
      generating,
      updateRows,
      pagination,
      loading,
      quotes
    };
  }
});
